<template>
  <div class="InsideModels1A content-margin">
    <h2 class="InsideModels1A-title">{{ data.title }}</h2>
    <div class="InsideModels1A-container-slider" v-if="slides.length">
      <VueSlickCarouselc :lazyLoad="'progressive'" class="InsideModels1A-carousel" v-bind="settings" @afterChange="changesound">
        <template #prevArrow="">
          <div class="custom-arrow"><iconic name="newArrowBack" /></div>
        </template>

        <div
          v-for="(slide, i) in slides"
          class="InsideModels1A-carousel-slide"
          @mouseenter="insertComponent($event, i)"
          @mouseleave="removeComponent($event, i)"
          :key="i"
        >
          <button class="InsideModels1A-slide-btnPlay">
            <iconic name="btn_play" />
          </button>
          <div class="InsideModels1A-slide-hiddenWall" @touchstart="manageVideoPlay($event, true)" @touchend="manageVideoPlay($event, false)"></div>
          <img
            class="InsideModels1A-slide-img"
            :src="urlCloudFront($screen.width > 430 ? slides[i].poster.desktop : slides[i].poster.mobile)"
            alt="poster"
            v-show="!shouldShowVideo(i)"
          />
          <video
            class="InsideModels1A-slide-video"
            :class="{ 'video-blur': showFullText }"
            :src="slide.media"
            :autoplay="$screen.width < 820"
            :muted="indexVideoWithSound !== i"
            playsinline
            v-show="shouldShowVideo(i)"
          ></video>
          <div class="InsideModels1A-slide-footer" :class="{ expanded: showFullText }">
            <div class="InsideModels1A-slide-buttons">
              <div class="InsideModels1A-slide-interactions">
                <button type="button" class="InsideModels1A-interactions mute" @click="manageVideoSound($event, i)" @click.stop>
                  <iconic :name="indexVideoWithSound !== i ? 'mute' : 'unmute'" :key="indexVideoWithSound" />
                </button>
                <button class="InsideModels1A-interactions btn-rounded" @click="goToVideo(slide.idTexts)">
                  {{ language === "es" ? "Ver video" : "Show video" }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <template #nextArrow="">
          <div class="custom-arrow"><iconic name="newArrowNext" /></div>
        </template>
      </VueSlickCarouselc>
    </div>
    <shareModal ref="shareModal" />
    <WebCastComment ref="WebCastComments" />
  </div>
</template>
<script>
import VueSlickCarouselc from "vue-slick-carousel";
import SignupButton from "./SignupButton.vue";
import shareModal from "../modals/sharePostModal.vue";
import WebCastComment from "./WebCastComments.vue";
export default {
  components: { VueSlickCarouselc, SignupButton, shareModal, WebCastComment },
  props: ["data"],
  data: function () {
    return {
      showFullText: false,
      maxCharactersToShow: 150,
      indexVideoWithSound: -1,
      indexVideoPlaying: -1,
      language: "es",
    };
  },
  computed: {
    shouldShowVideo() {
      return (i) => {
        return this.indexVideoPlaying === i || this.$screen.width < 1024;
      };
    },

    settings() {
      const breakpoints = [
        { breakpoint: 370, slidesToShow: 1 },
        { breakpoint: 495, slidesToShow: 1 },
        { breakpoint: 620, slidesToShow: 1 },
        { breakpoint: 1023, slidesToShow: 2 },
        { breakpoint: 1160, slidesToShow: 3 },
        { breakpoint: 1450, slidesToShow: 4 },
        { breakpoint: 1500, slidesToShow: 4 },
        { breakpoint: 1600, slidesToShow: 4 },
      ];

      const responsive = breakpoints.map(({ breakpoint, slidesToShow }) => {
        return { breakpoint, settings: { arrows: true, autoplay: false, slidesToShow } };
      });

      return {
        arrows: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptiveHeight: true,
        infinite: true,
        autoplay: false,
        cssEase: "linear",
        responsive,
      };
    },
    slides() {
      return this.data.multimedia.map((media) => {
        return {
          type: ["youtube", "video"].includes(media.platformType) ? "video" : "image",
          media: media.url.desktop,
          poster: media.poster,
          idTexts: media.texts._id,
        };
      });
    },
  },
  mounted() {
    window.addEventListener("storage", () => {
      this.language = localStorage.getItem("user-language");
    });
  },
  methods: {
    urlCloudFront(link) {
      return this.$global.newUrl(link);
    },
    changesound() {
      if (this.indexVideoWithSound >= 0) {
        const carouselContainer = document.querySelector(".InsideModels1A-carousel");
        carouselContainer.querySelectorAll("video").forEach((item) => {
          item.muted = true;
        });
        this.indexVideoWithSound = -1;
      }
    },

    toggleLike(index) {
      const device = this.$screen.width >= 1024 ? "desktop" : "mobile";
      this.slides[device][index].liked = !this.slides[device][index].liked;
    },

    goToVideo(id) {
      const videoSelected = document.querySelector(`[data-textId=textId-${id}]`);
      videoSelected.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        videoSelected.click();
      }, 700);
    },

    foundParentElement(evt) {
      let parentElementDiv = evt.target;
      let isSlideElement = evt.target.classList.contains("InsideModels1A-carousel-slide");
      while (!isSlideElement) {
        parentElementDiv = parentElementDiv.parentElement;
        isSlideElement = parentElementDiv.classList.contains("InsideModels1A-carousel-slide");
      }
      return parentElementDiv;
    },
    manageVideoSound(evt, index) {
      let parentElementDiv = this.foundParentElement(evt);
      const video = parentElementDiv.querySelector("video");
      const isMuted = this.indexVideoWithSound !== index;

      video.muted = !isMuted;
      this.indexVideoWithSound = isMuted ? index : -1;
    },
    manageVideoPlay(evt, isPlaying) {
      let parentElementDiv = this.foundParentElement(evt);
      const video = parentElementDiv.querySelector("video");
      if (isPlaying) video?.pause();
      else video?.play();
    },
    insertComponent(evt, index) {
      if (this.$screen.width >= 1024) {
        this.indexVideoPlaying = index;
        let parentElementDiv = this.foundParentElement(evt);
        parentElementDiv.querySelector("video").play();
      }
    },
    removeComponent(evt, index) {
      if (this.$screen.width >= 1024) {
        this.indexVideoPlaying = -1;
        this.indexVideoWithSound = -1;
        let parentElementDiv = this.foundParentElement(evt);
        parentElementDiv.querySelector("video").pause();
        parentElementDiv.querySelector("video").currentTime = 0;
      }
    },
    toggleText() {
      if (this.$screen.width <= 768) {
        this.showFullText = !this.showFullText;
        const textShort = document.querySelectorAll(".InsideModels1A-text-short");
        document.querySelectorAll(".slick-arrow").forEach((arrow) => arrow.classList.toggle("hide"));
        textShort.forEach((arrow) => arrow.classList.toggle("hide-text"));
      }
    },
  },
};
</script>

<style lang="scss">
.InsideModels1A {
  position: relative;
  overflow: hidden;
  margin-top: 50px;
  @keyframes slick-carousel {
    0% {
      transform: translate3d(0px, 0px, 0px);
    }
    100% {
      transform: translate3d(0px, 0px, 0px);
    }
  }
  @keyframes likeAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  .content-margin {
    margin-bottom: 0;
  }
  &-title {
    padding: 10px;
    color: #fff;
    background: $primary_color;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
  }
  .InsideModels1A-slide-btnPlay {
    display: none;
  }
  &-carousel {
    video {
      width: 100%;
    }
    button {
      color: #fff;
      background: transparent;
    }

    &-slide {
      position: relative;
      overflow: hidden;
      height: 690px;
      box-shadow: 0px 1px 7px 0px #000000d9;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: -4px;
        width: 100%;
        height: 700px;
        z-index: 1;
        border-radius: 12px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.15) 68.16%, rgba(0, 0, 0, 0.7) 86%);
      }
      .InsideModels1A-slide-hiddenWall {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
      }
      .btn-rounded {
        font-size: 14px;
        background: $primary_color;
        padding: 7px 15px;
        border-radius: 20px;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        font-family: $first_font;
        font-weight: bold;
        &:hover {
          transform: scale(1.1);
        }
      }
      .InsideModels1A-slide-footer {
        position: absolute;
        z-index: 40;
        bottom: 0;
        color: #fff;
        height: auto;
        width: 100%;
        h3 {
          margin-bottom: 7px;
          text-transform: uppercase;
        }
        .InsideModels1A-footer {
          &-text {
            cursor: pointer;
            max-height: 150px;
            min-height: 90px;
            overflow: hidden;
            padding: 0 20px;
            font-size: 14px;
            transition: max-height 0.4s ease-in-out;
            &.expandContainer {
              max-height: 500px;
              overflow: visible;
              .InsideModels1A-text-full {
                display: block;
              }
            }
            .hide-text {
              display: none;
            }
            .InsideModels1A-text {
              &-full {
                margin-top: 10px;
                max-height: 350px;
                overflow: auto;
                display: none;
              }
              &-full,
              &-short {
                transition: 0.3s ease-in-out;
              }
            }
          }
        }
      }
      video.video-blur {
        transition: filter 0.3s ease-in-out;
        filter: blur(3px);
      }

      .InsideModels1A-slide-buttons {
        display: flex;
        justify-content: space-between;
        padding: 20px;
      }

      .InsideModels1A-slide-interactions {
        display: flex;
        align-items: center;
        gap: 30px;
        width: 85%;
        .InsideModels1A-interactions {
          display: flex;
          gap: 5px;
          align-items: flex-end;
          .iconic {
            font-size: 23px;
          }
          span {
            font-weight: 200;
            font-size: 18px;
          }
        }
        .liked {
          animation: likeAnimation 0.2s ease-in-out;
          .iconic {
            color: #fff;
          }
        }
      }
    }

    .slick-arrow {
      top: 50%;
      display: flex;
      align-items: center;
      transition: opacity 0.3s ease;
      &.hide {
        opacity: 0;
      }
    }
    .slick-prev,
    .slick-next {
      z-index: 100;
      height: 100%;
      width: 27px;
    }
    .slick-prev::before,
    .slick-next::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .slick-prev {
      left: 0px;
      height: 100px;
      border-radius: 1rem;
    }
    .slick-next {
      right: 13px;
      height: 100px;
    }

    .custom-arrow {
      margin: auto;
      z-index: 20;
      .iconic {
        font-size: 50px;
        text-align: center;
        color: white;
        .arrow {
          color: white;
        }
      }
    }
  }
  @include MobileSmall {
    .InsideModels1A-buttons-signin {
      visibility: hidden;
    }
    &-slide-interactions {
      .like,
      .comment,
      .share {
        visibility: hidden;
      }
    }
    &-carousel {
      &-slide {
        .InsideModels1A-slide-footer {
          margin-bottom: 5px;
        }
        .InsideModels1A-footer-text {
          cursor: pointer;
          max-height: 115px;
        }
      }
    }
  }
  @include Tablet {
    &-slide-interactions {
      width: 100%;
    }
    &-carousel {
      .slick-track {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }
    }
    .slick-next {
      right: 20px;
      height: 100px;
    }
  }
  @include Desktop {
    h2 {
      text-align: left;
      padding-left: 0;
      color: $primary_color;
      background: transparent;
    }
    &-slide-interactions {
      justify-content: space-between;
    }
    &-carousel {
      &-slide {
        position: relative;
        width: 290px;
        height: 450px;
        cursor: pointer;
        border-radius: 15px;
        overflow: hidden;
        img,
        video {
          border-radius: 15px;
          max-width: 100%;
          max-height: 500px;
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: -4px;
          width: 100%;
          height: 460px;
          z-index: 1;
          border-radius: 12px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.15) 80.16%, rgba(0, 0, 0, 0.7) 94%);
        }

        .InsideModels1A-slide-btnPlay {
          visibility: hidden;
          position: absolute;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 50%;
          height: 70px;
          width: 70px;
          top: 0;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          .iconic {
            font-size: 30px;
          }
        }
        &:hover > .InsideModels1A-slide-btnPlay {
          visibility: visible;
          transition: visibility 0.3s ease-in;
        }
        .InsideModels1A-slide-buttons {
          display: block;
        }
        .InsideModels1A-slide-footer {
          height: auto;
          z-index: 20;
          background: none;

          .InsideModels1A-footer-text {
            max-height: 100%;
            height: 100%;
            max-width: 100%;
            display: none;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px;
            .InsideModels1A-text-full {
              display: none;
            }
          }
        }
      }
    }
    .InsideModels1A-slide-interactions {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    .slick-list {
      width: 100%;
      .slick-track {
        display: flex;
        justify-content: space-between;
        gap: 40px;
      }
    }
  }
}
</style>
